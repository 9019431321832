<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">NEW MENU</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-checkbox v-model="is_cooked" label="For Cook?"
                          class="me-3 mt-1"></v-checkbox>
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="menu_title"
                    label="Menu Title"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="amount_food_panda"
                    label="Food Panda Amount"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="amount_grab"
                    label="Grab Amount"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12" v-if="id===0">
                  <v-select
                    v-model="branch_info"
                    :items="branch_items"
                    item-text="branch_code"
                    item-value="id"
                    label="Branch"
                    dense
                    outlined
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>

                <v-col md="4" cols="12">
                  <v-avatar
                    rounded
                    size="300"
                    class="me-6"
                  >
                    <v-img :src="cropped"></v-img>
                  </v-avatar>
                  <v-btn
                    color="info"
                    class="me-3 mt-5"
                    @click="$refs.refInputEl.click()"
                  >
                    <v-icon class="d-sm-none">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">Upload Photo</span>
                  </v-btn>
                  <input
                    ref="refInputEl"
                    type="file"
                    accept=".jpeg,.png,.jpg"
                    :hidden="true"
                    v-on:click="$refs.refInputEl.value = ''"
                    v-on:change="croppie"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Branch</th>
              <th class="text-uppercase">Menu</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Food Panda Amount</th>
              <th class="text-center text-uppercase">Grab Amount</th>
              <th class="text-center text-uppercase">Status</th>
              <th class="text-center text-uppercase">Edit</th>
              <th class="text-center text-uppercase">Deactivate</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in initialize_datas" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.branch.branch_code }}
              </td>
              <td>
                {{ item.title}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount)}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount_food_panda)}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount_grab)}}
              </td>
              <td class="text-center">
                <div v-if="item.is_active===1">
                  ACTIVE
                </div>
                <div v-if="item.is_active===0">
                  DEACTIVATED
                </div>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="!is_updating"
                  class="mr-2"
                  color="success"
                  @click="update(item)"
                >
                  {{icons.mdiPencilBoxOutline}}
                </v-icon>
                <v-progress-circular color="info" indeterminate
                                     v-else></v-progress-circular>
              </td>
              <td class="text-center">
                <div v-if="item.is_active===1">
                  <v-icon
                    v-if="!is_updating"
                    class="mr-2"
                    color="error"
                    @click="update_status(item,0)"
                  >
                    {{icons.mdiToggleSwitchOff}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
                <div v-if="item.is_active===0">
                  <v-icon
                    v-if="!is_updating"
                    class="mr-2"
                    color="success"
                    @click="update_status(item,1)"
                  >
                    {{icons.mdiToggleSwitch}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="is_edit_amount">
      <v-card>
        <v-form ref="form2" class="multi-col-validation mt-6">
          <v-card-text>
            <v-row>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="menu_title"
                  label="Menu Title"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="amount_food_panda"
                  label="Food Panda Amount"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="amount_grab"
                  label="Grab Amount"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>

              <v-col md="4" cols="12">
                <v-avatar
                  rounded
                  size="300"
                  class="me-6"
                >
                  <v-img :src="cropped"></v-img>
                </v-avatar>
                <v-btn
                  color="info"
                  class="me-3 mt-5"
                  @click="$refs.refInputEl.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Upload Photo</span>
                </v-btn>
                <input
                  ref="refInputEl"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl.value = ''"
                  v-on:change="croppie"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-alert color="warning" text class="mb-0" v-show="alert">
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alert_message }}
                </p>
              </div>
            </div>
          </v-alert>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!saving"
            color="primary"
            @click="update_info"
          >
            Update Changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
          <!-- alert -->

        </v-card-actions>
      </v-card>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiToggleSwitch,
    mdiToggleSwitchOff,
    mdiPencilBoxOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/1.png'),
      avatarImg: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_1: '',

      branch_info: '',
      branch_items: [],

      saving: false,
      alert: false,
      alert_message: '',

      is_edit_amount: false,
      b_id: 0,
      id: 0,
      amount: '0',
      amount_food_panda: '0',
      amount_grab: '0',
      email: '',
      menu_title: '',
      position: '',

      initialize_datas: [],
      selected_to_edit: {},
      is_updating: false,
      is_cooked: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
          mdiToggleSwitch,
          mdiToggleSwitchOff,
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id_selected', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['get_branches_active']),
      ...mapActions('menu_order', ['register_new_menu', 'get_all_menu', 'update_new_menu', 'update_status_new_menu']),
      croppie(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage_mem_1 = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },

      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
      },
      initialize_data() {
        this.get_all_menu({
          branch_id: this.branch_id_selected
        })
          .then(response => {
            this.initialize_datas = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.get_branches_active()
          .then(response => {
            this.branch_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('is_for_cooked', this.is_cooked ? 1 : 0)
          data.append('branch_id', this.branch_info)
          data.append('title', this.menu_title.toUpperCase(),)
          data.append('amount', this.amount)
          data.append('amount_food_panda', this.amount_food_panda)
          data.append('amount_grab', this.amount_grab)
          data.append('photo_1', this.croppieImage_mem_1)
          this.register_new_menu(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      update_status(item, status) {
        this.update_status_new_menu({
          id: item.id,
          status: status,
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      update(item) {
        this.amount = item.amount
        this.amount_grab = item.amount_grab
        this.amount_food_panda = item.amount_food_panda
        this.menu_title = item.title
        this.selected_to_edit = item
        this.is_edit_amount = true
      },
      update_info() {
        if (this.$refs.form2.validate()) {
          this.update_new_menu({
            id: this.selected_to_edit.id,
            title: this.menu_title.toUpperCase(),
            amount: this.amount,
            amount_food_panda: this.amount_food_panda,
            amount_grab: this.amount_grab,
            photo_1: this.croppieImage_mem_1,
          })
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
